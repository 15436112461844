import { LoginResponse } from 'shared/api/requests/auth';

const AUTH_DATA = 'cob:auth_data';
export const LANG_DATA = 'cob:lang_data';
export const LANG_DATA_DEFAULT = 'cob:lang_data_default';
const NAV = 'cob:nav';

export const localStorageService = {
  setNavigate: (val: string) => {
    localStorage.setItem(NAV, val);
  },
  getNavigate: (): string | null => {
    const data = localStorage.getItem(NAV);
    return data;
  },
  removeNavigate: () => {
    localStorage.removeItem(NAV);
  },
  setLangDefaultData: (val: string) => {
    localStorage.setItem(LANG_DATA_DEFAULT, val);
  },
  getLangDefaultData: (): boolean => {
    const data = localStorage.getItem(LANG_DATA_DEFAULT);
    if (data && data !== 'undefined') {
      return !!data;
    }
    return false;
  },
  setLangData: (val: string) => {
    localStorage.setItem(LANG_DATA, val);
  },
  getLangData: (): string => {
    const data = localStorage.getItem(LANG_DATA);
    if (data && data !== 'undefined') {
      return data;
    }
    return 'en';
  },
  setAuthData: (authInfo: LoginResponse): void => {
    localStorage.setItem(AUTH_DATA, JSON.stringify(authInfo));
  },
  getAuthData: (): LoginResponse | null => {
    const data = localStorage.getItem(AUTH_DATA);
    if (data && data !== 'undefined') {
      return JSON.parse(data);
    }
    return null;
  },
  removeAuthData: (): void => {
    localStorage.removeItem(AUTH_DATA);
  },
};
