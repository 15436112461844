import React, { useEffect, useMemo } from 'react';

import { Form } from 'antd';
import FormItem from 'shared/ui/form-item';
import Input from 'shared/ui/input';
import { useTranslation } from 'react-i18next';
import Button from 'shared/ui/button';
import { useForm } from 'antd/es/form/Form';
import { postDCConnectAccountRequest, postDCConnectUnlinkRequest } from 'shared/api/requests/dc';
import { useAppDispatch, useAppSelector } from 'shared/store/hooks';
import { getMeAction } from 'app/store/actions';
import { cx } from 'shared/utils';
import { Link } from 'react-router-dom';
import { selectedUserId } from 'app/store/app.selectors';

import './styles.scss';

const getStatusText = (status: string) => {
  switch (status) {
    case 'new':
      return 'new';
    case 'in_progress':
      return 'in_progress';
    case 'done':
      return 'done';
    case 'rejected':
      return 'rejected';
    default:
      return 'rejected';
  }
};

const IrAcademy = () => {
  const [form] = useForm();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const reezonlyData = useAppSelector((state) =>
    state.app?.me?.account_data.dcconnector_accounts.find((el) => el.dc_connector.connector_name === 'reezonly')
  );
  const userId = useAppSelector(selectedUserId);

  const email = Form.useWatch('email', form);

  const disabled = reezonlyData?.state !== 'rejected';

  const defaultValue = useMemo(
    () => (reezonlyData?.params ? JSON.parse(reezonlyData?.params) : { email: '' }),
    [reezonlyData?.params]
  );

  useEffect(() => {
    form.setFieldsValue(defaultValue);
  }, [defaultValue, form]);

  const onSubmit = async () => {
    const values = form.getFieldsValue();

    const payload = {
      email: values.email as string,
      type: 'reezonly',
    };

    await postDCConnectAccountRequest(payload);
    await dispatch(getMeAction());
  };

  const onDisconnect = async () => {
    const payload = {
      email: defaultValue as string,
      type: 'reezonly',
    };
    await postDCConnectUnlinkRequest(payload);
    await dispatch(getMeAction());
  };

  const classNames = cx('settings-reezonly-status', {
    [`settings-reezonly-status--${reezonlyData?.state}`]: reezonlyData?.state,
  });

  return (
    <div className="settings-reezonly">
      <span className="settings-reezonly__title">Reezonly</span>
      <span className="settings-reezonly__text">{t('iracademy.text')}</span>

      <div className={classNames}>
        <span className="settings-reezonly-status__text">
          {t<string>(`iracademy.status.${getStatusText(reezonlyData?.state || '')}`)}
          {'.'}
        </span>
        {reezonlyData?.state && reezonlyData?.state === 'new' ? (
          <Link
            target="_blank"
            to={`https://lms.reezonly.com/cf-confirm?LmsEmail=${email}&CfUserId=${userId}&CfRedirectUrl=${window.location.href}`}
          >
            {t('iracademy.status.in_progress_link')}
          </Link>
        ) : null}
      </div>

      <div className="settings-reezonly__wrap">
        <Form
          form={form}
          className="settings-reezonly__form"
          layout="vertical"
          requiredMark={false}
          onFinish={onSubmit}
        >
          <FormItem
            name="email"
            label={t<string>('loginForm.email.label')}
            rules={[{ required: true, type: 'email', message: t<string>('loginForm.error.plsEmail') }]}
          >
            <Input
              size="large"
              placeholder={t<string>('loginForm.email.placeholder')}
              disabled={reezonlyData && disabled}
            />
          </FormItem>
          {reezonlyData?.state === 'done' ? (
            <Button size="large" type="primary" onClick={onDisconnect}>
              {t('iracademy.disconnect')}
            </Button>
          ) : (
            <Button htmlType="submit" size="large" type="primary">
              {t('iracademy.submit')}
            </Button>
          )}
        </Form>
      </div>
    </div>
  );
};

export default IrAcademy;
